var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title:
              _vm.useType == "student"
                ? "Gửi thông báo Sms cho học sinh"
                : "Gửi thông báo Sms cho nhân sự",
            visible: _vm.dialogVisible,
            width: "1200px",
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false,
            top: "3vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataInput",
              attrs: {
                "label-width": "110px",
                model: _vm.dataInput,
                "label-position": "left",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { display: "inline-block", width: "25%" },
                  attrs: { label: "Hẹn giờ gửi", prop: "timer" },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: {
                        change: function ($event) {
                          return _vm.changeStatusSend()
                        },
                      },
                      model: {
                        value: _vm.dataInput.timer,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataInput, "timer", $$v)
                        },
                        expression: "dataInput.timer",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("Gửi ngay"),
                      ]),
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Hẹn giờ"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { display: "inline-block", width: "40%" },
                  attrs: { prop: "dateTime" },
                },
                [
                  _vm.dataInput.timer
                    ? _c("el-date-picker", {
                        attrs: {
                          format: "HH:mm dd-MM-yyyy ",
                          "value-format": "yyyy-MM-dd HH:mm",
                          type: "datetime",
                          placeholder: "Chọn giờ gửi",
                        },
                        model: {
                          value: _vm.dataInput.dateTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataInput, "dateTime", $$v)
                          },
                          expression: "dataInput.dateTime",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { display: "inline-block", width: "49%" },
                  attrs: { label: "Nhập nội dung", prop: "sendContent" },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 10,
                      maxlength: "500",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.dataInput.sendContent,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataInput, "sendContent", $$v)
                      },
                      expression: "dataInput.sendContent",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { float: "right", width: "49%" },
                  attrs: { label: "Hiện thị", prop: "sendContent" },
                },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: "",
                      type: "textarea",
                      rows: 10,
                      maxlength: "500",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.textShow,
                      callback: function ($$v) {
                        _vm.textShow = $$v
                      },
                      expression: "textShow",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger", size: "medium" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialog()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-close" }),
                  _c("span", [_vm._v(_vm._s(_vm.$t("button.close")))]),
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "success",
                    size: "medium",
                    loading: _vm.loadingButton,
                    mini: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-check" }),
                  _c("span", [_vm._v("Gửi")]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }